<template>
  <div class="fingerprint">
    <div class="form_box" v-show="!isShowStateBox">
      <div class="title_box">
        <img src="../../../../assets/images/real-line-left.png" class="img" />
        <div class="text">电子数据指纹核验</div>
        <img src="../../../../assets/images/real-line-right.png" class="img" />
      </div>

      <el-form
        label-position="left"
        :model="fingerprintForm"
        :rules="fingerprintrules"
        ref="fingerprintRuleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="源文件生成数据指纹">
          <input
            id="upload"
            style="display: none"
            type="file"
            name="frontFile"
            ref="backFile"
            @change="doRealUpload"
          />
          <div v-show="!proofMaterial" class="upimg" @click="uploadFile">
            点击上传文件
          </div>
          <div v-show="proofMaterial" class="upimg" @click="uploadFile">
            识别成功
          </div>
        </el-form-item>
        <el-form-item label="电子数据指纹" prop="fingerprint">
          <el-input
            v-model="fingerprintForm.fingerprint"
            placeholder="请输入电子数据指纹"
          ></el-input>
        </el-form-item>
        <el-form-item label="可信时间凭证标识号" prop="credentials">
          <el-input
            v-model="fingerprintForm.credentials"
            placeholder="请输入可信时间凭证标识号"
          ></el-input>
        </el-form-item>

        <el-form-item class="btn">
          <el-button type="primary" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="state_box" v-show="isShowStateBox">
      <div class="img_box">
        <img v-if="getData" src="../../../../assets/images/success_2.png" />
        <img v-else src="../../../../assets/images/error_img.png" />
        <!-- : '../../../../assets/images/error_img.png' -->
        <p class="text">电子数据指纹验证{{ getData ? "成功" : "失败" }}</p>
      </div>

      <div class="data_box">
        <div class="item_box">
          <div class="item">
            <div class="title">可信时间凭证标识号：</div>
            <div class="number">
              {{ getData.sn ? getData.sn : fingerprintForm.credentials }}
            </div>
          </div>
          <i :class="getData.rid ? 'success_iocn_box' : 'error_iocn_box'"></i>
        </div>
        <div class="item_box">
          <div class="item">
            <div class="title">电子数据指纹：</div>
            <div class="number">
              {{ getData.rid ? getData.rid : fingerprintForm.fingerprint }}
            </div>
          </div>
          <i :class="getData.sn ? 'success_iocn_box' : 'error_iocn_box'"></i>
        </div>
        <div class="item_box">
          <div class="item">
            <div class="title">认证时间：</div>
            <div class="number">
              {{ getData.ts ? getData.ts : "2021-12-22 14:35:00" }}
            </div>
          </div>
          <i :class="getData.ts ? 'success_iocn_box' : 'error_iocn_box'"></i>
        </div>
      </div>

      <div class="btn_box">
        <el-button v-if="getData" type="primary" @click="download" plain
          >下载可信官方凭证</el-button
        >
        <el-button
          v-else
          type="primary"
          @click="
            fingerprintForm.fingerprint = '';
            fingerprintForm.credentials = '';
            isShowStateBox = false;
            proofMaterial = false;
          "
          >返回</el-button
        >
      </div>
    </div>
    <!-- <div class="error_box"></div> -->
    <!-- <el-button type="primary" @click="download" plain>下载</el-button> -->
  </div>
</template>

<script>
import { existevidenceCheck, getFingerprint } from "api/copyrightService";

// import { downloadFile } from "api/common";
export default {
  data() {
    return {
      // state_box 隐藏
      isShowStateBox: false,
      // upimg div show
      proofMaterial: false,
      // 电子数据指纹数据
      getData: "",

      formUrl: "",
      // 表单
      fingerprintForm: {
        fingerprint: "",
        credentials: "",
      },
      fingerprintrules: {
        fingerprint: [
          { required: true, message: "请输入电子数据指纹", trigger: "blur" },
        ],
        credentials: [
          {
            required: true,
            message: "请输入可信时间凭证标识号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 触发上传事件
    uploadFile() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },

    // 上传
    async doRealUpload(e) {
      let file = e.target.files[0];
      let form = new FormData();
      form.append("file", file);

      const res = await getFingerprint(form);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "识别成功",
        });
        this.fingerprintForm.fingerprint = res.data;
        this.proofMaterial = true;
      } else {
        this.$message({
          type: "error",
          message: "识别失败",
        });
      }
    },

    // 提交
    async submitForm() {
      let form = new FormData();
      form.append("rid", this.fingerprintForm.fingerprint);
      form.append("sn", this.fingerprintForm.credentials);
      // const data = {
      //   rid: this.fingerprintForm.fingerprint,
      //   sn: this.fingerprintForm.credentials,
      // };

      // console.log(data);
      const res = await existevidenceCheck(form);
      // console.log(res);
      if (res.code === 200) {
        // console.log(res);
        this.getData = res.data.data;
        this.formUrl = res.data;
        console.log(this.getData);
        this.isShowStateBox = true;
      } else {
        this.state = false;
        this.isShowStateBox = true;
      }
    },

    // 下载
    download() {
      // let url = "group1/M00/00/0B/L2pUHWITCoyAGm1FAAu__O_UYM8650.pdf";
      // let url1 = "group1/M00/00/0B/L2rchmITCouAWVhwAAinNQyd3P4240.pdf";

      // console.log(this.formUrl.owner);
      // let form = new FormData();
      // form.append("fileUrl", this.formUrl.owner);
      // form.append("fileName", "存证.pdf");
      // // console.log(form.get("fileUrl"));
      // // console.log(form.get("fileName"));
      // let params = {
      //   fileUrl: url,
      //   fileName: "存证.pdf",
      // };
      // JSON.stringify(params);
      // const IFRAME = document.createElement("iframe");
      // IFRAME.style.display = "none";
      // IFRAME.style.height = 0;
      // // src 就是请求服务地址自行修改。
      // // https://fafs.antiplagiarize.com/
      // IFRAME.src = `${this.$store.state.onlineBasePath}common/downloadFile?fileUrl=${this.formUrl.official}&fileName=存证.pdf`;
      // document.body.appendChild(IFRAME);
      // setTimeout(() => {
      //   IFRAME.remove();
      // }, 60 * 1000);
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = this.$store.state.onlineBasePath + this.formUrl.official;
      a.click();
      a.remove();
    },
  },

  created() {
    // this.$bus.$emit("realNamePopup");
    // if(this.$store.state.realNameData)
  },
};
</script>

<style lang="scss" scoped>
.fingerprint {
  height: 800px;
  .form_box {
    width: 570px;
    margin: 0 auto;

    .title_box {
      margin: 80px 0 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        width: 89px;
        height: 12px;
      }

      .text {
        margin: 0 35px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
    }

    .upimg {
      color: #ff6900;
      border: 1px solid #dcdfe6;
      padding: 0 0 0 15px;
      border-radius: 5px;
      cursor: pointer;
    }

    .btn {
      text-align: right;
    }
  }

  .state_box {
    margin: 0 auto;
    .img_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        margin: 40px 0;
      }

      .text {
        font-weight: 800;
      }
    }

    .data_box {
      flex-direction: column;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .item_box {
        margin: 10px 0;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          display: flex;
          .title {
            width: 160px;
            margin-right: 20px;
          }
          .number {
            color: #ff6900;
          }
        }

        .success_iocn_box {
          width: 17px;
          height: 17px;

          @include backgroundGroup("../../../../assets/images/success_1.png");
        }
        .error_iocn_box {
          width: 17px;
          height: 17px;

          @include backgroundGroup("../../../../assets/images/error.png");
        }
      }
    }

    .btn_box {
      margin-top: 50px;
      text-align: center;
    }
  }
}
</style>
